import { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Loader from "@components/structures/loader/Loader";
import { debugMode } from "@common/constants";
import errorState from "@redux/Error/error-reducer";
import checkApplicationError, { tryToShowApplicationError } from "@common/applicationError";
import "@i18n/config";
import "core-js/proposals/promise-all-settled";

import "@src/App.scss";

if (!debugMode) {
    const methods = ["info", "log", "debugg", "error", "warn"];
    methods.forEach(method => {
        // eslint-disable-next-line no-console
        console[method] = () => {};
    });
}

const Contact = lazy(() => import("@components/views/contact/Contact"));
const ChatFrame = lazy(() => import("@components/views/chat-frame/ChatFrame"));
const ChatRoom = lazy(() => import("@components/views/chat-room/ChatRoom"));
const ChatPage = lazy(() => import("@components/views/chat-page/ChatPage"));
const NewRoom = lazy(() => import("@components/views/new-room/NewRoom"));
const UserInfo = lazy(() => import("@components/views/user-info/UserInfo"));
const ErrorPage = lazy(() => import("@components/views/error-page/ErrorPage"));

const App = () => {
    const [isAppError, appErrorMessage] = checkApplicationError();
    const [error, setError] = useState(isAppError);
    const [errorMessage, setErrorMessage] = useState(appErrorMessage);

    useEffect(() => {
        errorState.subscribe(() => {
            import("@common/events").then(({ eventEmitter }) => {
                const logoutValue = errorState.getState().value;
                const isError = logoutValue.state;
                if (isError) {
                    eventEmitter.emit("chatFrameIncrease");
                    Promise.resolve().then(() => {
                        setError(isError);
                        setErrorMessage(logoutValue.message);
                    });
                }
            });
        });

        // because if bad internet we can see minimized error message in iframe
        tryToShowApplicationError(isAppError);
    }, []);

    return error ? (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Route render={() => <ErrorPage message={errorMessage} />} />
            </Suspense>
        </BrowserRouter>
    ) : (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Route
                    path="/chat-frame"
                    render={() => <ChatFrame />}
                />
                <Route
                    path="/chat-room"
                    render={() => <ChatRoom />}
                />
                <Route
                    path="/new-room"
                    render={() => <NewRoom />}
                />
                <Route
                    path="/contact"
                    render={() => <Contact />}
                />
                <Route
                    path="/chat-page"
                    render={() => <ChatPage />}
                />
                <Route
                    path="/user-info"
                    render={() => <UserInfo />}
                />
            </Suspense>
        </BrowserRouter>
    );
};

export default App;
