import loaderSrc from "@img/loader.gif";
import classNames from "classnames";
import s from "./Loader.module.scss";

const Loader = ({ bg = false }) => {
    const CN = {
        loader: classNames({
            [s.loader]: true,
            [s.loaderBg]: bg,
        }),
    };

    return (
        <div className={CN.loader}>
            <img
                src={loaderSrc}
                alt="loader"
            ></img>
        </div>
    );
};

export default Loader;
