import Platform from "@common/platform";
import { debugMode, validDomains } from "@common/constants";

const checkApplicationError = () => {
    let block = false;
    let message = "";
    const url = new URL(global.location.href);
    Platform.domain = url.searchParams.get("domain");

    // Check local storage support
    try {
        if (window.localStorage) {
            // eslint-disable-next-line no-console
            console.log("The Local storage is supported, continue...");
        }
    } catch (e) {
        block = true;
        message = "Your browser does not support local storages or they are disabled in the settings";

        return [block, message];
    }

    // Check indexedDB storage support
    try {
        if (window.indexedDB) {
            // eslint-disable-next-line no-console
            console.log("The IndexedDB storage is supported, continue...");
        }
    } catch (e) {
        block = true;
        message = "Your browser does not support indexed db storage or they are disabled in the settings";

        return [block, message];
    }

    try {
        if (window.crypto?.subtle) {
            // eslint-disable-next-line no-console
            console.log("The Crypto subtle is supported, continue...");
        } else {
            /* Тут добавлен debug mode для того чтобы локально не вылезало на пол экрана
             * у нас на разработке на локалке iframe с ошибкой,
             * наверно хорошо было бы добавить кнопку закрытия в ошибке */
            // eslint-disable-next-line no-lonely-if
            if (!debugMode) {
                block = true;
                message = "Your browser does not support crypto";

                return [block, message];
            }
        }
    } catch (e) {
        block = true;
        message = "Your browser does not support crypto";

        return [block, message];
    }

    // Check if chat is oppened from iframe
    if (window.location === window.parent.location) {
        block = true;
        message = "No direct script access allowed.";

        return [block, message];
    }

    // eslint-disable-next-line no-console
    console.log("The script access allowed, continue...");

    // Check if domain sended in get param is valid
    if (!new RegExp(validDomains).test(Platform.domain)) {
        block = true;
        message = "Invalid domain.";
    } else {
        // eslint-disable-next-line no-console
        console.log("The domain is valid, continue...");
    }

    // eslint-disable-next-line no-console
    console.log("All is fine, start to initialize application...");

    return [block, message];
};

const tryToShowApplicationError = state => {
    if (state) {
        const interval = setInterval(() => {
            if (global.innerHeight > 60) {
                clearInterval(interval);
            }
            import("@common/events").then(({ eventEmitter }) => {
                eventEmitter.emit("chatFrameIncrease");
            });
        }, 50);
    }
};

export { tryToShowApplicationError };
export default checkApplicationError;
