import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { applicationLanguage } from "@common/constants";

i18n.use(initReactI18next).init({
    fallbackLng: applicationLanguage,
    lng: applicationLanguage,
    resources: {
        en: {
            // eslint-disable-next-line global-require
            translations: require("./locales/en/translations.json"),
        },
    },
    ns: ["translations"],
    defaultNS: "translations",
});

i18n.languages = ["en"];

export default i18n;
