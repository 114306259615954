import React from "react";
import ReactDOM from "react-dom";
import App from "@src/App";

import loadOlm from "@common/olm";

// Olm crypto loading
loadOlm();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
