// Debug mode
export const debugMode = Boolean(Number(process.env.REACT_APP_DEBUG_MODE));
// Origin link for post messages
export const validDomains = process.env.REACT_APP_VALID_DOMAINS_REGEX;
// Link for our matrix api, save link for another api to test("https://matrix-client.matrix.org")
export const apiUrl = process.env.REACT_APP_API_URL;
export const shortApiUrl = process.env.REACT_APP_SHORT_API_URL;
// Encryption algoritm
export const cryptoAlgorytm = "m.megolm.v1.aes-sha2";
// Maximum room per page for lazy loading rooms on scroll
export const roomsPerPage = 10;
// Timeline per page on lazy loading
export const initialTimeline = 15;
export const timelinePerPage = 15;
// Validaiton maximum symbols in message textarea
export const maxValidSymbolsForMessage = 1500;
export const maxSubject = 200;
// Maximal selected users to create new rooms for each selected user with theme and message
export const maxSelectUsers = 3;
// Presence request for 1 user, delay to get request to cache data
export const delayForUserPresence = 6e4;
// Profile room prefix
export const profileRoomsAliasPrefix = "#profile.";
// Indexes DB name
export const idbStoreName = "sync";
export const idbCryptoName = "matrix-js-sdk:crypto";
export const idbOurStorageName = "matrix-js-sdk:data";
export const vendorPrefix = "com.exportportal";
// Type name
export const msgTypeFile = `${vendorPrefix}.file`;
// Account data keys
export const keyPushRules = `${vendorPrefix}.rules`;
// Storage keys
export const keyStorageUser = `${vendorPrefix}.login.user`;
export const keyStorageUserId = `${vendorPrefix}.login.userId`;
export const keyStorageDeviceId = `${vendorPrefix}.login.deviceId`;
export const keyStorageAccessToken = `${vendorPrefix}.login.acessToken`;
// Event names
export const pushRulesEventName = `${vendorPrefix}.push.rules`;
export const unreadRoomsEventName = `${vendorPrefix}.unread.rooms`;
export const profileRoomsEventName = `${vendorPrefix}.profile`;
export const isProfileRoomEventName = `${vendorPrefix}.profile_room`;
export const cargoNotificationEventName = `${vendorPrefix}.notice`;
// Tag names
export const cargoTagName = `${vendorPrefix}.cargo`;
// Secret storage
export const SECRET_STORAGE_ALGORITHM_V1_AES = "m.secret_storage.v1.aes-hmac-sha2";

// Lang
export const applicationLanguage = "en";
