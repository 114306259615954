const platformParams = new Map();

platformParams.set("roomIsLazy", new Map());
platformParams.set("roomLazyLoaders", new Map());
platformParams.set("profileUserMap", new Map());
platformParams.set("listOfPendingEvents", new Map());
platformParams.set("firstLoading", true);
platformParams.set("clientOnline", true);
platformParams.set("showOfflineStatus", false);
platformParams.set("isTimelineListenerActive", true);

const Platform = {
    // Global of export portal
    set domain(val) {
        platformParams.set("domain", val);
    },

    get domain() {
        return platformParams.get("domain");
    },

    // Global of export portal
    set parent(val) {
        platformParams.set("parent", val);
    },

    get parent() {
        return platformParams.get("parent");
    },

    // Matrix api base url
    set baseUrl(val) {
        platformParams.set("baseUrl", val);
    },

    get baseUrl() {
        return platformParams.get("baseUrl");
    },

    // User Client
    set userClient(val) {
        platformParams.set("userClient", val);
        // Set client.crypto to Platform.crypto
        this.crypto = val?.crypto;
    },

    get userClient() {
        return platformParams.get("userClient");
    },

    // crypto from client
    set crypto(val) {
        platformParams.set("crypto", val);
    },

    get crypto() {
        return platformParams.get("crypto");
    },

    // Authentication data
    set data(val) {
        platformParams.set("data", val);
    },

    get data() {
        return platformParams.get("data");
    },

    // Room is Open
    set activeRoom(val) {
        platformParams.set("activeRoom", val);
    },

    get activeRoom() {
        return platformParams.get("activeRoom");
    },

    // Checker is this is page iframe
    set isPage(val) {
        platformParams.set("isPage", val);
    },

    get isPage() {
        return platformParams.get("isPage");
    },

    // main User Information
    set mainUserInformation(val) {
        platformParams.set("mainUserInformation", val);
    },

    get mainUserInformation() {
        return platformParams.get("mainUserInformation");
    },

    // --- CRYPTO --- //
    // Passphrase
    set passPhrase(val) {
        platformParams.set("passPhrase", val);
    },

    get passPhrase() {
        return platformParams.get("passPhrase");
    },

    set recoveryKey(val) {
        platformParams.set("recoveryKey", val);
    },

    get recoveryKey() {
        return platformParams.get("recoveryKey");
    },

    set keyBackupFromPassPhrase(val) {
        platformParams.set("keyBackupFromPassPhrase", val);
    },

    get keyBackupFromPassPhrase() {
        return platformParams.get("keyBackupFromPassPhrase");
    },

    set keyBackupFromRecoveryKey(val) {
        platformParams.set("keyBackupFromRecoveryKey", val);
    },

    get keyBackupFromRecoveryKey() {
        return platformParams.get("keyBackupFromRecoveryKey");
    },

    set secretStorageKeys({ key, val }) {
        const keys = platformParams.get("secretStorageKeys");
        if (keys) {
            keys.set(key, val);
        } else {
            const map = new Map();
            map.set(key, val);
            platformParams.set("secretStorageKeys", map);
        }
    },

    /** @type {Map<string, Uint8Array>} */
    get secretStorageKeys() {
        return platformParams.get("secretStorageKeys");
    },

    set secretStorageKeyInfo({ key, val }) {
        const keys = platformParams.get("secretStorageKeyInfo");
        if (keys) {
            keys.set(key, val);
        } else {
            const map = new Map();
            map.set(key, val);
            platformParams.set("secretStorageKeyInfo", map);
        }
    },

    /** @type {Map<string, import("matrix-js-sdk").ISecretStorageKeyInfo>} */
    get secretStorageKeyInfo() {
        return platformParams.get("secretStorageKeyInfo");
    },

    set secretStorageBeingAccessed(val) {
        platformParams.set("secretStorageBeingAccessed", val);
    },

    get secretStorageBeingAccessed() {
        return platformParams.get("secretStorageBeingAccessed");
    },

    set dehydrationCache(val) {
        platformParams.set("dehydrationCache", val);
    },

    get dehydrationCache() {
        return platformParams.get("dehydrationCache") || {};
    },

    set promiseRestoreKeys(val) {
        platformParams.set("promiseRestoreKeys", val);
    },

    get promiseRestoreKeys() {
        return platformParams.get("promiseRestoreKeys");
    },

    set profileUserMap({ key, val }) {
        const keys = platformParams.get("profileUserMap");
        if (keys) {
            keys.set(key, val);
        } else {
            const map = new Map();
            map.set(key, val);
            platformParams.set("profileUserMap", map);
        }
    },

    get profileUserMap() {
        return platformParams.get("profileUserMap");
    },

    set roomLazyLoaders({ key, val }) {
        const keys = platformParams.get("roomLazyLoaders");
        if (keys) {
            keys.set(key, val);
        } else {
            const map = new Map();
            map.set(key, val);
            platformParams.set("roomLazyLoaders", map);
        }
    },

    get roomLazyLoaders() {
        return platformParams.get("roomLazyLoaders");
    },

    set firstLoading(val) {
        platformParams.set("firstLoading", val);
    },

    get firstLoading() {
        return platformParams.get("firstLoading");
    },

    set roomIsLazy({ key, val }) {
        const keys = platformParams.get("roomIsLazy");
        if (keys) {
            keys.set(key, val);
        } else {
            const map = new Map();
            map.set(key, val);
            platformParams.set("roomIsLazy", map);
        }
    },

    get roomIsLazy() {
        return platformParams.get("roomIsLazy");
    },

    set secretStorageResetWithBrokenKeyBackup(val) {
        platformParams.set("secretStorageResetWithBrokenKeyBackup", val);
    },

    get secretStorageResetWithBrokenKeyBackup() {
        return platformParams.get("secretStorageResetWithBrokenKeyBackup");
    },

    set firstSetUnread(val) {
        platformParams.set("firstSetUnread", val);
    },

    get firstSetUnread() {
        return platformParams.get("firstSetUnread");
    },

    set isInitCrypto(val) {
        platformParams.set("isInitCrypto", val);
    },

    get isInitCrypto() {
        return platformParams.get("isInitCrypto");
    },

    set clientOnline(val) {
        platformParams.set("clientOnline", val);
    },

    get clientOnline() {
        return platformParams.get("clientOnline");
    },

    set showOfflineStatus(val) {
        platformParams.set("showOfflineStatus", val);
    },

    get showOfflineStatus() {
        return platformParams.get("showOfflineStatus");
    },

    set listOfPendingEvents(val) {
        platformParams.set("listOfPendingEvents", val);
    },

    get listOfPendingEvents() {
        return platformParams.get("listOfPendingEvents");
    },

    set isTimelineListenerActive(val) {
        platformParams.set("isTimelineListenerActive", val);
    },

    get isTimelineListenerActive() {
        return platformParams.get("isTimelineListenerActive");
    },

    set activeRoomData(val) {
        platformParams.set("activeRoomData", val);
    },

    get activeRoomData() {
        return platformParams.get("activeRoomData");
    },
};

export default Platform;
