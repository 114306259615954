import Olm from "olm";

const olmWasmPath = "olm/olm.wasm";
const loadOlm = () => {
    Olm.init({
        locateFile: () => olmWasmPath,
    })
        .then(() => {
            // eslint-disable-next-line no-console
            console.log("Using WebAssembly Olm");
        })
        .catch(e => {
            // eslint-disable-next-line no-console
            console.log("Failed to load Olm: trying legacy version", e);
            return import("olm/olm_legacy")
                .then(({ default: OlmFirstParam }) => {
                    // @ts-ignore
                    global.Olm = OlmFirstParam;
                    return OlmFirstParam;
                })
                .then(OlmParam => {
                    return OlmParam.init().catch(el => {
                        // eslint-disable-next-line no-console
                        console.log("Both WebAssembly and asm.js Olm failed!", el);
                    });
                });
        });
};

export default loadOlm;
