import { createSlice, configureStore } from "@reduxjs/toolkit";

export const error = createSlice({
    name: "error",
    initialState: {
        value: {
            state: false,
            message: "",
        },
    },
    reducers: {
        showError: (state, { payload: { message } }) => {
            state.value.state = true;
            state.value.message = message;
        },
    },
});

export const { showError } = error.actions;
export default configureStore({
    reducer: error.reducer,
});
